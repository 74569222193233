.form-ramadan {
    background-image: url('../images/ramadanTheme/header.svg'); 
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
    position: absolute;
    top:0;
    padding: 0px 43% 22%;
}

.ramadan-logo div{
    background-image: url('../images/ramadanTheme/logo.png'); 
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
    position: absolute;
    top: 70%;
    left: 25%;
    padding: 0px 35% 40%;
} 
.ramadan-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.skip-button {
    background-image: url('../images/ramadanTheme/skip-moon.png'); 
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
   right:12px;
   bottom: -5px;
    width: 30px;
    height: 30px;
}
.ramadan-button div {
    background-image: url('../images/ramadanTheme/mosque.png'); 
    background-size: contain;
    background-repeat: repeat;
    position: absolute;
    left: 0;
    z-index: 0;
    bottom: 0;
    width: 100%;
    height: 25px;
}

.ramadan-button span {
    position: relative;
    z-index: 1;
}

.ramadan-cover{
    background-image: url('../images/ramadanTheme/profile-moon.svg'); 
    background-size: contain;
    background-position: top left;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 5%;
    width: 100%;
    height: 55%;
}