/* Variable Declaration */
:root {
  --activeBackground: #47a82e;
  --notActiveBackground: #e4e4e4;
}
/* Start Stepper Style */
.stepper {
  display: flex;
  flex-direction: row;
  padding: 25px 15px 0;
  cursor: context-menu;
}
.stepper .step {
  display: flex;
}
.stepper .status {
  display: flex;
  align-items: center;
  position: relative;
}
.stepper .text {
  margin: 0;
}
.stepper .dot-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 22px;
  position: absolute;
  left: -20px;
  font-size: 9px;
  font-weight: 600;
  gap: 5px;
}
.stepper .dot {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--notActiveBackground);
  color: #696969;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7px;
}
.stepper .line {
  display: block;
  margin: 0 auto;
  width: 45px;
  height: 8px;
  background: var(--notActiveBackground);
  border: 2px solid var(--notActiveBackground);
}
.stepper .active {
  background-color: var(--activeBackground);
  border: 2px solid var(--notActiveBackground);
  color: white;
}
/* End Stepper Style*/