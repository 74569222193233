/* Quest List */
.quest-table {
  position: relative;
}
.tableHover {
  cursor: pointer;
}
.tableHover::before {
  font-size: 12px;
  color: white;
  background-color: rgb(76, 76, 76);
  font-weight: 300;
  padding: 5px;
  border: 1px solid rgb(219, 219, 219);
  opacity: 0;
  transition: opacity 0.3s;
}
.tableHover:hover::before {
  opacity: 1;
}
.sort::before {
  content: "Sort BY";
  position: absolute;
  top: 45px;
}
.progress-hover::before {
  content: "Phase progress";
  position: absolute;
  top: 45px;
}
.days::before {
  content: "Next phase deadline";
  position: absolute;
  top: 45px;
}
/* Quest View */
.quest-view {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.quest-view .overview {
  min-width: fit-content;
}
.quest-view .details {
  width: 100%;
}
.quest-view .tasks {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: flex-start;
  gap: 20px;
}
@media (max-width: 992px) and (max-width: 60.9375rem) {
  .quest-view {
    flex-wrap: wrap;
    justify-content: center;
  }
  .quest-view .overview {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .quest-view .tasks {
    grid-template-columns: auto;
  }
}

/* Quest Registration */
.text-inputs-width {
  width: 27.33%;
  min-width: 350px;
}

.feedback-width {
  width: 35%;
  min-width: 350px;
}

p.user-agreement,
.feedback-slider {
  font-size: 1.143rem;
}

.link {
  color: var(--primary);
}

.color-primary {
  color: var(--primary);
}

.a-style {
  color: var(--primary) !important;
}

.a-style:hover {
  text-decoration: underline;
}

/*winner*/
.position-relative {
  position: relative;
}
.winner-name{
  position:absolute;
  bottom: 120%;
  left:-100%;
  z-index:1;
  font-size: 10px;
  width:max-content;
  padding:5px;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.winner-circle{
  width: 30px; 
  height: 30px; 
  font-size:10px;
  color:white;
  border-radius:10%; 
  cursor: pointer;
}

.position-relative:hover .winner-circle + .winner-name {
  opacity: 1;
}
@media (min-width:  47.9375rem) and (max-width:60.9375rem) {
    .winners{
        display:flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}